import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./mozaic.scss";
import { motion, useAnimation } from "framer-motion";
import { StoreContext } from "../../store/store";
import useIsMobile from "../../utils/hooks/useIsMobile";
import { Pagination } from "../../components/Pagination/Pagination";
import { Sound } from "../../components/Sound/Sound";
import useSwipe from "../../utils/hooks/useSwipe";

export const Mozaic = forwardRef((props, ref) => {
  const { section } = props;
  const { listBo = {} } = section ?? {};
  const { lang } = useContext(StoreContext);

  const controls = useAnimation();
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);
  const isMobile = useIsMobile();
  const [currentIndex, setCurrentIndex] = useState(0);

  // Swiper

  const [indexChangeSource, setIndexChangeSource] = useState(null);

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
  });

  const handlePrev = () => {
    setIndexChangeSource("click");
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? listBo.data.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIndexChangeSource("click");
    setCurrentIndex((prevIndex) =>
      prevIndex === listBo.data.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    if (indexChangeSource === "click" && containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const scrollPosition = currentIndex * containerWidth;

      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });

      setTimeout(() => {
        setIndexChangeSource(null);
      }, 500);
    }
  }, [currentIndex, indexChangeSource]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleScroll = () => {
        if (indexChangeSource !== "click") {
          const containerWidth = container.getBoundingClientRect().width;
          const scrollPosition = container.scrollLeft;
          const newIndex = Math.round(scrollPosition / containerWidth);

          if (newIndex !== currentIndex) {
            setCurrentIndex(newIndex);
          }
        }
      };

      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [currentIndex, indexChangeSource]);

  // Intersection observer

  useEffect(() => {
    if (isMobile) {
      setIsVisible(true);
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isMobile]);

  useEffect(() => {
    if (isVisible) {
      controls.start("visible");
    }
  }, [controls, isVisible]);

  const itemVariants = {
    hidden: (index) => ({
      opacity: 0,
      x: index % 2 === 0 ? "-10%" : "10%",
    }),
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  // Pagination

  const [displayedBo, setDisplayedBo] = useState(
    isMobile ? listBo.data : listBo.data.slice(0, 6)
  );

  useEffect(() => {
    setDisplayedBo(isMobile ? listBo.data : listBo.data.slice(0, 6));
  }, [listBo.data, isMobile]);

  const handlePageChange = (newArray) => {
    setDisplayedBo(newArray);
    const yOffset = -92;
    const y =
      sectionRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const videosRef = useRef([]);

  return (
    <section ref={sectionRef} className="mozaic-section">
      <div className="mozaic-container">
        <div {...swipeHandlers} ref={containerRef} className="mozaic-wrapper">
          {displayedBo.map((news, index) => {
            const {
              media,
              shortContent,
              btnUrl,
              subTitle,
              title,
              listLogo,
              isBlack,
            } = news[lang] ?? {};

            const isBlackContent = isBlack.data;

            return (
              <motion.div
                key={index}
                className="mozaic-content-container"
                custom={index}
                variants={itemVariants}
                initial={isVisible ? "visible" : "hidden"}
                animate={controls}
                transition={{ delay: index * 0.1 }}
              >
                <div
                  className={`mozaic-content-container-wrapper ${
                    isBlackContent ? "order-first" : "order-last"
                  }`}
                >
                  <div
                    className={`mozaic-content ${
                      isBlackContent ? "bg-grey" : "bg-beige"
                    }`}
                  >
                    <div
                      className="mozaic-content-container-left-scroll"
                      onClick={handlePrev}
                    >
                      <img
                        src="/icon/chevron-left.svg"
                        alt="chevron-left"
                        draggable="false"
                      />
                    </div>
                    <div className="mozaic-content-container-wrapper-content">
                      <div className="mozaic-content-upper">
                        <span
                          className={`mozaic-content-title ${
                            isBlackContent ? "light" : ""
                          }`}
                        >
                          {title.data}
                        </span>

                        <span
                          className={`mozaic-content-subtitle ${
                            isBlackContent ? "light" : ""
                          }`}
                        >
                          {subTitle.data}
                        </span>
                      </div>

                      <div
                        className={`mozaic-content-description ${
                          isBlackContent ? "light" : ""
                        }`}
                        dangerouslySetInnerHTML={{ __html: shortContent.data }}
                      />

                      {btnUrl.data && (
                        <a
                          className={`mozaic-content-download ${
                            isBlackContent ? "light" : ""
                          }`}
                          href={btnUrl.data}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {lang === "fr" ? "Télécharger" : "Download"}
                        </a>
                      )}

                      <div className="mozaic-content-companies-container">
                        {listLogo.data.map((company, index) => {
                          const { media } = company[lang] ?? {};

                          return (
                            <img
                              src={media.data.path}
                              alt={media.data.alt}
                              key={index}
                              className="mozaic-content-companies-container-logo"
                              draggable="false"
                            />
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="mozaic-content-container-right-scroll"
                      onClick={handleNext}
                    >
                      <img
                        src="/icon/chevron-right.svg"
                        alt="chevron-right"
                        draggable="false"
                      />
                    </div>
                  </div>
                </div>

                {media.data && (
                  <div
                    className={`mozaic-content-image-container ${
                      isBlackContent ? "order-last" : "order-first"
                    }`}
                  >
                    {media.data.mimeType.startsWith("video/") ? (
                      <>
                        <video
                          ref={(el) => (videosRef.current[index] = el)}
                          src={media.data.path}
                          alt={media.data.alt}
                          autoPlay
                          loop
                          muted
                          width="100%"
                          draggable="false"
                          playsInline
                          className="mozaic-content-image"
                        />
                        <div className="sound-container">
                          <Sound videoRef={videosRef} index={index} />
                        </div>
                      </>
                    ) : (
                      <img
                        src={media.data.path}
                        alt={media.data.alt}
                        className="mozaic-content-image"
                        draggable="false"
                      />
                    )}
                  </div>
                )}
              </motion.div>
            );
          })}
        </div>
      </div>

      {!isMobile && (
        <Pagination
          array={listBo.data}
          newsPerPage={6}
          onPageChange={handlePageChange}
        />
      )}

      {isMobile && (
        <div className="mozaic-bullets">
          {displayedBo.map((_, index) => (
            <div
              key={index}
              className={`mozaic-bullet ${
                index === currentIndex ? "active" : ""
              }`}
              onClick={() => {
                setIndexChangeSource("click");
                setCurrentIndex(index);
              }}
            />
          ))}
        </div>
      )}
    </section>
  );
});
